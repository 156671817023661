export enum SummaryTextEnum {
    現在の期間 = "現在の期間",
    入力状態 = "入力状態",
    エラー = "エラー",
    メッセージ = "メッセージ",
    担当窓口 = "担当窓口",
    申請が必要な書類 = "申請が必要な書類",
    申請要領注意文言 = "申請要領注意文言",
    申請が必要なPDF = "申請が必要なPDF",
    記入の上申請が必要なPDF = "記入の上、申請が必要なPDF",
    申請が必要な書類のPDF一覧 = "申請が必要な書類のPDF一覧",
    申請手順 = "申請手順",
    期間外 = "期間外",
    新規入力入力内容確認 = "新規入力入力内容確認",
    免除結果シミュレーション = "免除結果シミュレーション",
    入力の確定後に免除結果シミュレーションを使用できます = "入力の確定後に免除結果シミュレーションを使用できます",
    上記以外にも提出が必要な書類があります必ず申請要領で確認して申請してください = "※上記以外にも提出が必要な書類があります。必ず申請要領で確認して申請してください。"
}

export function SummaryTextEnumToName(e: SummaryTextEnum): { ja: string, en: string } {
    switch (e) {
        case SummaryTextEnum.現在の期間:
            return {ja: "現在の期間", en: "Current period"};
        case SummaryTextEnum.期間外:
            return {ja: "期間外", en: "Outside the period"};
        case SummaryTextEnum.入力状態:
            return {ja: "入力状態", en: "Data entry status"};
        case SummaryTextEnum.エラー:
            return {ja: "エラー", en: "Errors"};
        case SummaryTextEnum.メッセージ:
            return {ja: "メッセージ", en: "Messages"};
        case SummaryTextEnum.担当窓口:
            return {ja: "担当窓口", en: "Administrative Office"};
        case SummaryTextEnum.申請が必要な書類:
            return {ja: "申請が必要な書類", en: "Documents Requiring Application"};
        case SummaryTextEnum.申請要領注意文言:
            return {
                ja: "「申請要領2（証明書類について）」や「申請に必要な様式等の確認機能」を参照し、提出が必要な様式は以下のリンク先のページより印刷、記入のうえ、提出してください。",
                en: "Please refer to \"Application Guideline 2 (Regarding Certificates)\" and \"Confirmation Function for Forms\" and obtain the required forms form the link below to submit."
            };
        case SummaryTextEnum.申請が必要なPDF:
            return {
                ja: "申請が必要なPDF",
                en: "PDF Files Requiring Application"
            };
        case SummaryTextEnum.記入の上申請が必要なPDF:
            return {
                ja: "記入の上、申請が必要なPDF",
                en: "PDF Files Requiring Application After Completing them"
            };
        case SummaryTextEnum.申請が必要な書類のPDF一覧:
            return {
                ja: "申請が必要な書類のPDF一覧",
                en: "PDF list of documents that need to be applied for"
            };
        case SummaryTextEnum.申請手順
        :
            return {ja: "申請手順", en: "Application Procedure"};
        case SummaryTextEnum.新規入力入力内容確認:
            return {ja: "新規入力・入力内容確認", en: "New entry and entry Content Confirmation"};
        case SummaryTextEnum.免除結果シミュレーション:
            return {ja: "免除結果シミュレーション", en: "Waiver Result Simulation"};
        case SummaryTextEnum.入力の確定後に免除結果シミュレーションを使用できます:
            return {ja: "入力の確定後に免除結果シミュレーションを使用できます。", en: "Waiver result simulation can be used after input is confirmed."};
        case SummaryTextEnum.上記以外にも提出が必要な書類があります必ず申請要領で確認して申請してください:
            return {
                ja: "※上記以外にも提出が必要な書類があります。必ず申請要領で確認して申請してください。",
                en: "※There are more documents that must be submitted than the above. Please be sure to check the application guidelines before applying."
            };
        default:
            return {ja: "", en: ""};
    }
}
