export enum PdfEnum {
    授業料免除額家庭状況調書様式1 = "授業料免除額家庭状況調書様式1",
    授業料徴収猶予願別紙様式 = "授業料徴収猶予願別紙様式",
    入学料免除入学料徴収猶予願家庭状況調書別紙様式1 = "入学料免除入学料徴収猶予願家庭状況調書別紙様式1",
    大学等における修学の支援に関する法律による授業料等減免の対象者の認定に関する申請書A様式1 = "大学等における修学の支援に関する法律による授業料等減免の対象者の認定に関する申請書A様式1",
    大学等における修学の支援に関する法律による授業料減免の対象者の認定の継続に関する申請書A様式2 = "大学等における修学の支援に関する法律による授業料減免の対象者の認定の継続に関する申請書(A様式2)",
    // 申請要領学部新入生 = "申請要領学部新入生",
    // 申請要領学部在学生 = "申請要領学部在学生",
    申請要領学部在学生新制度 = "申請要領学部在学生新制度",
    申請要領学部在学生留学生コロナ = "申請要領学部在学生留学生コロナ",
    申請要領大学院新入生 = "申請要領大学院新入生",
    申請要領大学院在学生 = "申請要領大学院在学生",
    別紙1 = "別紙1",
    別紙2 = "別紙2",
    奨学金受給等申告書様式2 = "奨学金受給等申告書(様式2)",
    手当等申告書様式3 = "手当等申告書(様式3)",
    家庭事情申立書様式4 = "家庭事情申立書(様式4)",
    無職について様式42 = "無職について(様式4-2)",
    アルバイト証明書様式5 = "アルバイト証明書(様式5)",
    給与見込証明書様式6 = "給与見込証明書(様式6)",
    在学状況等証明書様式7 = "在学状況等証明書(様式7)",
    退離職証明書様式8 = "退(離)職証明書(様式8)",
    無職証明書様式9 = "無職証明書(様式9)",
    長期療養証明書様式10 = "長期療養証明書(様式10)",
    生活状況調書独立生計者私費外国人留学生様式11 = "生活状況調書(独立生計者・私費外国人留学生)(様式11)",
    介護サービス証明書様式12 = "介護サービス証明書(様式12)",
    配偶者用在学状況等証明書様式13 = "配偶者用 在学状況等証明書(様式13)",
    様式記入例 = "様式記入例",
    徴収猶予記入例 = "徴収猶予記入例",
    新型コロナウイルス感染症関係 = "新型コロナウイルス感染症関係",
    申請要領1_2 = "申請要領1_2",
    申請要領1_4 = "申請要領1_4",
    申請要領1_3 = "申請要領1_3",
    申請要領1_1 = "申請要領1_1",
    申請要領2 = "申請要領2",
}


export function PdfEnumToName(e: PdfEnum): { ja: string, en: string, ja_url: string, en_url: string } {
    switch (e) {
        case PdfEnum.授業料免除額家庭状況調書様式1:
            return {
                ja: "授業料免除額・家庭状況調書(様式1)",
                en: "Tuition Waiver Application - Statement of Household Finances(Form 1)",
                ja_url: "/syoruis/授業料免除額・家庭状況調書(様式1).pdf",
                en_url: "/syoruis/Tuition Waiver Application - Statement of Household Finances(Form 1).pdf"
            };
        case PdfEnum.授業料徴収猶予願別紙様式:
            return {
                ja: "授業料徴収猶予願(別紙様式)",
                en: "Tuition Payment Deferral Application(Appended Form)",
                ja_url: "/syoruis/授業料徴収猶予願(別紙様式).pdf",
                en_url: "/syoruis/Tuition Payment Deferral Application(Appended Form).pdf"
            };
        case PdfEnum.入学料免除入学料徴収猶予願家庭状況調書別紙様式1:
            return {
                ja: "入学料免除・入学料徴収猶予願・家庭状況調書(別紙様式1)",
                en: "Enrollment Fee Waiver-Deferral Application - Statement of Household Finances (Appended Form 1)",
                ja_url: "/syoruis/入学料免除・入学料徴収猶予願・家庭状況調書(別紙様式1).pdf",
                en_url: "/syoruis/Enrollment Fee Waiver-Deferral Application - Statement of Household Finances (Appended Form 1).pdf"
            };
        case PdfEnum.大学等における修学の支援に関する法律による授業料等減免の対象者の認定に関する申請書A様式1:
            return {
                ja: "大学等における修学の支援に関する法律による授業料等減免の対象者の認定に関する申請書(A様式1)",
                en: "Application for Approval of Persons Eligible for Reduction and Waivers of Tuition, etc. in Accordance with the Act to Support Study at Universities, etc.(Form A-1)",
                ja_url: "/syoruis/大学等における修学の支援に関する法律による授業料等減免の対象者の認定に関する申請書(A様式1).pdf",
                en_url: "/syoruis/Application for Approval of Persons Eligible for Reduction and Waivers of Tuition, etc. in Accordance with the Act to Support Study at Universities, etc.(Form A-1).pdf"
            };
        case PdfEnum.大学等における修学の支援に関する法律による授業料減免の対象者の認定の継続に関する申請書A様式2:
            return {
                ja: "大学等における修学の支援に関する法律による授業料減免の対象者の認定の継続に関する申請書(A様式2)",
                en: "Application Form for Continuation of Recognition for Tuition Fee Reduction and Exemption under the Law Concerning the Support of Study at Universities, etc.(Form A-2)",
                ja_url: "/syoruis/大学等における修学の支援に関する法律による授業料減免の対象者の認定の継続に関する申請書(A様式2).pdf",
                en_url: "/syoruis/Application Form for Continuation of Recognition for Tuition Fee Reduction and Exemption under the Law Concerning the Support of Study at Universities, etc.(Form A-2).pdf"
            };
        // NOTE: 申請要領の種類が変更になったのでコメントアウト 2023_spring
        // case PdfEnum.申請要領学部新入生:
        //     return {
        //         ja: "申請要領(学部 新入生)",
        //         en: "Application Guidelines(first-year undergraduate students)",
        //         ja_url: "/syoruis/申請要領(学部 新入生).pdf",
        //         en_url: "/syoruis/Application Guidelines(first-year undergraduate students).pdf"
        //     };
        // NOTE: 申請要領の種類が変更になったのでコメントアウト 2023_spring
        // case PdfEnum.申請要領学部在学生:
        //     return {
        //         ja: "申請要領(学部 在学生)",
        //         en: "Application Guidelines(enrolled undergraduate students)",
        //         ja_url: "/syoruis/申請要領(学部 在学生).pdf",
        //         en_url: "/syoruis/Application Guidelines(enrolled undergraduate students).pdf"
        //     };
        // 2025年用の申請要領
        case PdfEnum.申請要領1_2:
            return {
                ja: "申請要領1-2（高等教育の修学支援新制度） (令和7年度前期)",
                en: "",
                ja_url: "/syoruis/申請要領1-2（高等教育の修学支援新制度） (令和7年度前期).pdf",
                en_url: "/syoruis/"
            };
        case PdfEnum.申請要領1_4:
            return {
                ja: "申請要領1-4（高等教育の修学支援新制度） (令和7年度前期)",
                en: "",
                ja_url: "/syoruis/申請要領1-4（高等教育の修学支援新制度） (令和7年度前期).pdf",
                en_url: "/syoruis/"
            };
        case PdfEnum.申請要領1_3:
            return {
                ja: "申請要領1-3 (令和7年度前期)",
                en: "",
                ja_url: "/syoruis/申請要領1-3 (令和7年度前期).pdf",
                en_url: "/syoruis/"
            };
        case PdfEnum.申請要領1_1:
            return {
                ja: "申請要領1-1 (令和7年度前期)",
                en: "",
                ja_url: "/syoruis/申請要領1-1 (令和7年度前期).pdf",
                en_url: "/syoruis/"
            };
        case PdfEnum.申請要領2:
            return {
                ja: "申請要領2（証明書類について）",
                en: "",
                ja_url: "/syoruis/申請要領2（証明書類について）.pdf",
                en_url: "/syoruis/"
            };

        // ------------------

        case PdfEnum.申請要領学部在学生新制度:
            return {
                ja: "申請要領(学部在学生 新制度)",
                en: "Application Guidelines(enrolled undergraduate students for new system)",
                ja_url: "/syoruis/申請要領(学部在学生 新制度).pdf",
                en_url: "/syoruis/Application Guidelines(enrolled undergraduate students for new system).pdf"
            };
        case PdfEnum.申請要領学部在学生留学生コロナ:
            return {
                ja: "申請要領(学部在学生 留学生 コロナ)",
                en: "Application Guidelines(enrolled undergraduate students for old system, international students, COVID19)",
                ja_url: "/syoruis/申請要領(学部在学生 留学生 コロナ).pdf",
                en_url: "/syoruis/Application Guidelines(enrolled undergraduate students for old system, international students, COVID19).pdf"
            };
        case PdfEnum.申請要領大学院新入生:
            return {
                ja: "申請要領(大学院 新入生)",
                en: "Application Guidelines(first-year graduate students)",
                ja_url: "/syoruis/申請要領(大学院 新入生).pdf",
                en_url: "/syoruis/Application Guidelines(first-year graduate students).pdf"
            };
        case PdfEnum.申請要領大学院在学生:
            return {
                ja: "申請要領(大学院 在学生)",
                en: "Application Guidelines(enrolled graduate students)",
                ja_url: "/syoruis/申請要領(大学院 在学生).pdf",
                en_url: "/syoruis/Application Guidelines(enrolled graduate students).pdf"
            };
        case PdfEnum.別紙1:
            return {
                ja: "別紙1",
                en: "Attachment 1",
                ja_url: "/syoruis/別紙1.pdf",
                en_url: "/syoruis/Attachment 1.pdf"
            };
        case PdfEnum.別紙2:
            return {
                ja: "別紙2",
                en: "Attachment 2",
                ja_url: "/syoruis/別紙2.pdf",
                en_url: "/syoruis/Attachment 2.pdf"
            };
        case PdfEnum.奨学金受給等申告書様式2:
            return {
                ja: "奨学金受給等申告書(様式2)",
                en: "Scholarship Receipt Report(Form 2)",
                ja_url: "/syoruis/奨学金受給等申告書(様式2).pdf",
                en_url: "/syoruis/Scholarship Receipt Report(Form 2).pdf"
            };
        case PdfEnum.手当等申告書様式3:
            return {
                ja: "手当等申告書(様式3)",
                en: "Allowance Receipt Report(Form 3)",
                ja_url: "/syoruis/手当等申告書(様式3).pdf",
                en_url: "/syoruis/Allowance Receipt Report(Form 3).pdf"
            };
        case PdfEnum.家庭事情申立書様式4:
            return {
                ja: "家庭事情申立書(様式4)",
                en: "Family Situation Report(Form 4)",
                ja_url: "/syoruis/家庭事情申立書(様式4).pdf",
                en_url: "/syoruis/Family Situation Report(Form 4).pdf"
            };
        case PdfEnum.無職について様式42:
            return {
                ja: "無職について(様式4-2)",
                en: "About Unemployment(Form 4-2)",
                ja_url: "/syoruis/無職について(様式4-2).pdf",
                en_url: "/syoruis/About Unemployment(Form 4-2).pdf"
            };
        case PdfEnum.アルバイト証明書様式5:
            return {
                ja: "アルバイト証明書(様式5)",
                en: "Part-Time Job Certificate(Form 5)",
                ja_url: "/syoruis/アルバイト証明書(様式5).pdf",
                en_url: "/syoruis/Part-Time Job Certificate(Form 5).pdf"
            };
        case PdfEnum.給与見込証明書様式6:
            return {
                ja: "給与見込証明書(様式6)",
                en: "Certificate of Expected Wages(Form 6)",
                ja_url: "/syoruis/給与見込証明書(様式6).pdf",
                en_url: "/syoruis/Certificate of Expected Wages(Form 6).pdf"
            };
        case PdfEnum.在学状況等証明書様式7:
            return {
                ja: "在学状況等証明書(様式7)",
                en: "Enrollment Status Certificate(Form 7)",
                ja_url: "/syoruis/在学状況等証明書(様式7).pdf",
                en_url: "/syoruis/Enrollment Status Certificate(Form 7).pdf"
            };
        case PdfEnum.退離職証明書様式8:
            return {
                ja: "退(離)職証明書(様式8)",
                en: "Certificate of Retirement (Job Separation)(Form 8)",
                ja_url: "/syoruis/退(離)職証明書(様式8).pdf",
                en_url: "/syoruis/Certificate of Retirement (Job Separation)(Form 8).pdf"
            };
        case PdfEnum.無職証明書様式9:
            return {
                ja: "無職証明書(様式9)",
                en: "Certificate of Non-employment(Form 9)",
                ja_url: "/syoruis/無職証明書(様式9).pdf",
                en_url: "/syoruis/Certificate of Non-employment(Form 9).pdf"
            };
        case PdfEnum.長期療養証明書様式10:
            return {
                ja: "長期療養証明書(様式10)",
                en: "Certificate of Long-term Health Care(Form 10)",
                ja_url: "/syoruis/長期療養証明書(様式10).pdf",
                en_url: "/syoruis/Certificate of Long-term Health Care(Form 10).pdf"
            };
        case PdfEnum.生活状況調書独立生計者私費外国人留学生様式11:
            return {
                ja: "生活状況調書(独立生計者・私費外国人留学生)(様式11)",
                en: "Record of Living Conditions(Form 11)",
                ja_url: "/syoruis/生活状況調書(独立生計者・私費外国人留学生)(様式11).pdf",
                en_url: "/syoruis/Record of Living Conditions(Form 11).pdf"
            };
        case PdfEnum.介護サービス証明書様式12:
            return {
                ja: "介護サービス証明書(様式12)",
                en: "Nursing Care Services Certificate(Form 12)",
                ja_url: "/syoruis/介護サービス証明書(様式12).pdf",
                en_url: "/syoruis/Nursing Care Services Certificate(Form 12).pdf"
            };
        case PdfEnum.配偶者用在学状況等証明書様式13:
            return {
                ja: "配偶者用 在学状況等証明書(様式13)",
                en: "Certificate of Spouse’s Enrollment Status(Form 13)",
                ja_url: "/syoruis/配偶者用 在学状況等証明書(様式13).pdf",
                en_url: "/syoruis/Certificate of Spouse’s Enrollment Status(Form 13).pdf"
            };
        case PdfEnum.様式記入例:
            return {
                ja: "様式記入例",
                en: "Form Example",
                ja_url: "/syoruis/様式記入例.pdf",
                en_url: "/syoruis/Form Example.pdf"
            };
        case PdfEnum.徴収猶予記入例:
            return {
                ja: "徴収猶予記入例",
                en: "Deferral Application Form Example",
                ja_url: "/syoruis/徴収猶予記入例.pdf",
                en_url: "/syoruis/Deferral Application Form Example.pdf"
            };
        case PdfEnum.新型コロナウイルス感染症関係:
            return {
                ja: "新型コロナウイルス感染症関係",
                en: "About the COVID19",
                ja_url: "/syoruis/新型コロナウイルス感染症関係.pdf",
                en_url: "/syoruis/About the COVID19.pdf"
            };
    }
}
