export enum SyoruiTextEnum {
    申請要領の提出する証明書類についての項目や = "申請要領の提出する証明書類についての項目や",
    証明書類ダウンロード = "証明書類ダウンロード",
    書類一覧 = "書類一覧",
    申請要領 = "申請要領",
    各種証明書類等一覧 = "各種証明書類等一覧",
    証明書類 = "証明書類",
    申請が必要な書類のPDFを印刷して必要記入の上担当窓口へ提出してください = "申請が必要な書類のPDFを印刷して必要記入の上担当窓口へ提出してください",
    新入生は以下の証明書類を印刷して記入の上申請してください = "新入生は以下の証明書類を印刷して記入の上申請してください",
    システムから正しく出力がされない場合や手書きで申請される場合はこちらから書類をダウンロードできます = "システムから正しく出力がされない場合や、手書きで申請される場合はこちらから書類をダウンロードできます。",
    新入生は以下の申請要領を熟読しPDFを印刷記入の上申請してください = "新入生は以下の申請要領を熟読しPDFを印刷、記入の上申請してください。",
}


export function SyoruiTextEnumToName(e: SyoruiTextEnum): { ja: string, en: string } {
    switch (e) {
        case SyoruiTextEnum.申請要領の提出する証明書類についての項目や:
            return {
                ja: "申請要領の「提出する証明書類について」の項目や「申請に必要な様式等の確認機能」を参照してください。該当する様式等を印刷して記入の上、申請してください。",
                en: "Please refer to the \"About Certification Documents to Be Submitted\" section of the Application Guidelines and the \"Confirmation Function for Forms, etc. Requiring Application\". Please print and fill out the appropriate yoryos, etc., and submit the application."
            };
        case SyoruiTextEnum.証明書類ダウンロード:
            return {
                ja: "授業料・入学料免除提出書類様式はこちらからダウンロードしてください。",
                en: "Please download the required document templates for Tuition and Admission Fee Exemption from here.",
            };
        case SyoruiTextEnum.書類一覧:
            return {ja: "書類一覧", en: "Document Files"};
        case SyoruiTextEnum.申請要領:
            return {ja: "申請要領", en: "Application Guidelines"};
        case SyoruiTextEnum.各種証明書類等一覧:
            return {ja: "各種証明書類等一覧", en: "List of Documentation"};
        case SyoruiTextEnum.証明書類:
            return {ja: "証明書類", en: "Documentation PDF Files"};
        case SyoruiTextEnum.新入生は以下の証明書類を印刷して記入の上申請してください:
            return {
                ja: "新入生は以下のPDFを印刷して記入の上、申請してください。在学生は本システムで入力し確定後に、入力内容が反映されたPDFをトップページからダウンロードすることができます。",
                en: "First-year students are required to print, fill out the following PDF files before applying. Enrolled students can download PDF files from the top page after entering and confirming the information in this system."
            };
        case SyoruiTextEnum.システムから正しく出力がされない場合や手書きで申請される場合はこちらから書類をダウンロードできます:
            return {
                ja: "システムから正しく出力がされない場合や、手書きで申請される場合はこちらから書類をダウンロードできます。",
                en: "If the system does not output the documents correctly, or if you are applying by hand, you can download the documents here."
            };
        case SyoruiTextEnum.新入生は以下の申請要領を熟読しPDFを印刷記入の上申請してください:
            return {
                ja: "※新入生は以下の申請要領を熟読しPDFを印刷、記入の上申請してください。",
                en: "※First-year students must read the following Application Guidelines carefully, print out the PDF file, fill it out and submit the applicable documents."
            };

        default:
            return {ja: "", en: ""};
    }
}
