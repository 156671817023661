import {PictureAsPdf} from "@material-ui/icons";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {PdfEnum, PdfEnumToName} from "common/enums_text/PdfEnum";
import {SummaryTextEnum, SummaryTextEnumToName} from "common/enums_text/SummaryTextEnum";
import {TextLink} from "components/atoms/TextLink";
import React, {FC} from "react";
import {ContentBox} from "../../atoms/ContentBox";
import { Box } from "@material-ui/core";
import {TextColorsEnum} from "../../../common/enums_value/ColorsEnum";

interface Props {
    studentInfo: StudentInfoStoreVo
}

export const SyoruiListYoryo: FC<Props> = ({
    studentInfo,
}) => {

    // var pdfs
    // if (studentInfo.isGakubu) {
    //     if (studentInfo.isRyugakusei) {
    //         pdfs = [PdfEnum.申請要領1_3, PdfEnum.申請要領2]
    //     } else if (studentInfo.isSinnyusei) {
    //         pdfs = [PdfEnum.申請要領1_2]
    //     } else {
    //         pdfs = [PdfEnum.申請要領1_4]
    //     }
    // } else {
    //     if (studentInfo.isRyugakusei) {
    //         pdfs = [PdfEnum.申請要領1_3, PdfEnum.申請要領2]
    //     } else if (studentInfo.isSinnyusei) {
    //         pdfs = [PdfEnum.申請要領1_1, PdfEnum.申請要領2]
    //     } else {
    //         pdfs = [PdfEnum.申請要領1_3, PdfEnum.申請要領2]
    //     }
    // }

    return (

        <ContentBox>

            <Box fontWeight={700} color={TextColorsEnum.Red}>
                {convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.申請要領注意文言))}
            </Box>

            <div className={'mt8'}>

                <a href="https://www.tuat.ac.jp/campuslife_career/campuslife/fee/men_syou/menjoj/" target="_blank">
                    https://www.tuat.ac.jp/campuslife_career/campuslife/fee/men_syou/menjoj/
                </a>
                { /* pdfs.map((e) =>
                <div>
                <TextLink
                icon={<PictureAsPdf className={'icon'}/>}
                text={PdfEnumToName(e).ja}
                href={PdfEnumToName(e).ja_url}
                download={true}
                />
                {" / "}
                <TextLink
                icon={<PictureAsPdf className={'icon'}/>}
                text={PdfEnumToName(e).en}
                href={PdfEnumToName(e).en_url}
                download={true}
                />
                </div>
                ) */ }
            </div>

        </ContentBox>

    )
};
