import {PictureAsPdf} from "@material-ui/icons";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {RequiredSyoruisStoreVo, RequiredSyoruisSyoruisSyoruiVo} from "common/models/SyoruisModels";
import {SummaryTextEnum, SummaryTextEnumToName} from "common/enums_text/SummaryTextEnum";
import {SyoruiTextEnum, SyoruiTextEnumToName} from "common/enums_text/SyoruiTextEnum";
import {TextLink} from "components/atoms/TextLink";
import React, {FC} from "react";
import {BorderColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";
import {Box} from "@material-ui/core";
import {StudentInfoStoreVo} from "../../../common/models/yosiki/StudentInfoStoreVo";
import {Title05} from "../../atoms/titles/Title05";
import Grid from "@material-ui/core/Grid";
import {SyoruiListYoryo} from "../summaryStudentInfoSection/SyoruiListYoryo";

interface Props {
    studentInfo: StudentInfoStoreVo
    requiedSyoruis: RequiredSyoruisStoreVo
}

export const SyoruiLinkList: FC<Props> = ({
                                              studentInfo,
                                              requiedSyoruis,
                                          }) => {

    return (

        <>

            <Box pt={2} borderTop={`1px solid ${BorderColorsEnum.LightGray}`}>

                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Title05
                            title={"申請要領及び申請様式　Application Guidelines and Application Forms"}
                            // title={convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.申請が必要な書類のPDF一覧))}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {/* NOTE: 要領一覧 */}
                        <SyoruiListYoryo studentInfo={studentInfo}/>
                    </Grid>

                    {/*
                    <Grid item xs={12}>
                        <Box fontSize={12}>
                            {convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.申請要領の提出する証明書類についての項目や))}
                        </Box>
                    </Grid>
                      */}

                    <Grid item xs={12}>
                        <Box fontSize={12}>
                            {convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.証明書類ダウンロード))}
                        </Box>
                        <Box>
                            <a href="https://www.tuat.ac.jp/campuslife_career/campuslife/fee/men_syou/menjoyoshiki.html" target="_blank">
                                https://www.tuat.ac.jp/campuslife_career/campuslife/fee/men_syou/menjoyoshiki.html
                            </a>
                        </Box>

                    </Grid>

                    <Grid item xs={12}>
                        <Box fontWeight={700} color={TextColorsEnum.Red}>
                            {convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.上記以外にも提出が必要な書類があります必ず申請要領で確認して申請してください))}
                        </Box>
                    </Grid>

                </Grid>

            </Box>

        </>

    )
};
